@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poetsen+One&family=Space+Grotesk:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poetsen+One&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Space+Grotesk:wght@300..700&display=swap');

html{
  scroll-behavior: smooth
}

body {
  margin: 0;
  background-color: #e1e1e1;
}

.grid-section {
  position: relative;
  width: 100%;
}

.grid-section::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 1px, transparent 1px);
  background-size: 64px 64px;
  z-index: -1;
  /* transform: translateY(-calc(2 * '100%' + 2.25rem)); */
}

.project-number {
  /* add transform delay */

  transition: transform 0.4s ease-in-out;
}

.hollow {
  color: transparent;
}

.-z-1 {
  z-index: -1;
}

.hollow-letter {
  transition: all 0.1s linear;
  position: relative;
  /* z-index: 20; */
  -webkit-text-stroke: 2px black;
  /* color: transparent */
}

.hollow-letter::before {
  position: absolute;
  top: 0;
  left: -50%;
  width: 200%;
  height: 100%;
  content: "";
  text-align: center;
  opacity: 0;
  transition: all 0.1s linear;
  z-index: -5;
  border-radius: 50%;
}

.hollow-letter:hover::before {
  opacity: 0.15;
}

.transition-transparent {
  transition: all 2s linear;
  background-color: transparent;
  color: transparent;
}

.transition-transparent::before {
  background-color: transparent;
  color: transparent;
  transition: all 1.5s linear;
}

.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.chevron{
  transition: all 0.5s ease-in-out;
}